import React, {useRef, useState, useEffect} from 'react';
import "../../App.css";
 
export default function TextBox(props) {
	const [leftVal, setLeftVal] = useState('');	
    const textareaRef = useRef(); 
// to point to the input box child component, so that handleCursor()
// can access it

	const handleChange = (e) => {
		let myval = e.target.value;
		props.onChange(myval); // propagate the new text value up the parents stack, so we can update the value for this id in exerObj

	}

	const handleCursor = () => {
		let myval = props.value;
		let cursor = textareaRef.current.selectionStart; 
		const regex1 = /(?:^|\s)(\d+)(?=\s|$)/g;
		let match;
		let mytotal = 0;
		while ((match = regex1.exec(myval)) !== null) {
			mytotal += parseInt(match[1], 10); 
		}
// console.log('total='+mytotal)

		let newobj = JSON.parse(JSON.stringify(props.cursorPos));
		newobj[props.id]=cursor;
		props.setCursorPos(newobj);

		const regex2 = /\s(\d+)Total/g;
		match=regex2.exec(myval);
		if (match !== null){
			let oldpat = match[1]+'Total'
			let newpat = mytotal+'Total'
			myval = myval.replace(oldpat,newpat)
		}
		else {
			myval = myval+' '+mytotal+'Total'
		}
		props.onChange(myval);
		setLeftVal(myval.slice(0,cursor));

		newobj = JSON.parse(JSON.stringify(props.statusColor));
                let mylist = Object.entries(newobj);
                for(let entry of mylist){
                    newobj[entry[0]] = 'aqua';
                }
                newobj[props.id] = 'rgb(255, 137, 126)';
        props.setStatusColor(newobj);
	}
		
	const textStyle = {
		fontFamily: 'Courier',
		whiteSpace: 'pre',
		'fontSize': '20px',
		width: `${props.value.length}ch`,
		minWidth: '100%'
	};
	let mycursorPos = props.cursorPos[props.id];
        
            console.log('TextBox return, cursor=',props.cursorPos[props.id],' =>',props.value.slice(0,props.cursorPos[props.id]),' & ',props.value.slice(props.cursorPos[props.id]))
	
	return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '55%',
            position: 'relative'  // Added for absolute positioning context
        }}>
            <div style={{
                ...textStyle,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: '6px 10px',
                border: '1px solid #ccc',
            }}  >
                <span style={{ backgroundColor: props.statusColor[props.id] }}>{props.value.slice(0,props.cursorPos[props.id])}</span>{props.value.slice(props.cursorPos[props.id])}
            </div>

            <input
                style={{
                    ...textStyle,
                    position: 'relative',
                    zIndex: 2,
                    color: 'rgba(0, 255, 255, 0)',
                    //backgroundColor: 'rgba(255, 255, 255, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    border: '1px solid rgba(0,0,0,0)',
                }}
                value={props.value} 
                ref={textareaRef}
                onChange={(e)=>handleChange(e)} 
                onBlur={handleCursor}
			/>
		</div>

	);
	
}




