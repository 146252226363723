// Workout.js

import React from 'react';
import "../Components/Button.css";
import "../App.css";
import PullCloud from '../Components/PullCloud/PullCloud.js';
import SubmitButton from '../Components/SubmitButton/SubmitButton.js';
import ToggleSelect from '../Components/ToggleSelect/ToggleSelect.js';
import StopWatch from '../Components/StopWatch/StopWatch.js';
import TextLog from '../Components/TextLog/TextLog.js';
import TextBottom from '../Components/TextBottom/TextBottom.js';
import LogoutButton from '../Components/LogoutButton/LogoutButton.js';
import ExerElement from '../Components/ExerElement/ExerElement.js';
import { useEffect } from "react";

export function copyExer(exer1) {
	let a = new Object();
	a.name=exer1.name;
	a.value=exer1.value;
	a.selected=exer1.selected;
	return a;
}

function Genexer({exerObj,setExerObj,writestate,textvalue,cursorPos,setCursorPos,
    statusColor, setStatusColor}){
	function handleChangeForm(id, val){
		let newobj = JSON.parse(JSON.stringify(exerObj));
		newobj[id].value = val;
		setExerObj(newobj);
		writestate(newobj, textvalue);
	}
	function handleToggle(id){
		let newobj = JSON.parse(JSON.stringify(exerObj));
		newobj[id].selected = !(newobj[id].selected)
		setExerObj(newobj);
		writestate(newobj, textvalue);
	}
	  let ret=[];
	  let exerlist = Object.entries(exerObj);
	  exerlist.sort((a, b) => a[0]-b[0]);
	  let mykey=0;
	  for(let entry of exerlist){
            console.log('Genexer, id=',entry[0],' value=',entry[1])
		ret.push(<ExerElement exername={entry[1].name}
		key={mykey++} 
		id={entry[0]} value={entry[1].value} 
		cursorPos={cursorPos} setCursorPos={setCursorPos}
		statusColor={statusColor} setStatusColor={setStatusColor}
		selected={entry[1].selected}
		onChangeForm= {(id,value)=>handleChangeForm(id,value)}
		onToggle = {(id)=>handleToggle(id)} />); 
	  }
	  return(
		<>
		{ret}
		</>
		);
	}

export default function Workout(props) {

	useEffect(()=>{
		// put any intialization code here
		// note that this is called before authenticated,
		// so cannot yet access server data
		const timeout = setTimeout(() => {
			props.readstate();
		  }, 200);
	  
		// Cleanup to avoid memory leaks
		return () => clearTimeout(timeout);
	}, []);
	
	
	
  
    return (
        <div>
            <div className='fixed2' >
                <PullCloud {...props} />
                <ToggleSelect {...props} />
            </div> /* fixed2 */
            <div className='fixed3'>
                <StopWatch {...props} />
            </div>
                        
            <div style={{padding:'100px 20px 120px 20px'}}>
            /* rest of the flex */
                <div style={{display:'flex', justifyContent:'center'}}>
                        <SubmitButton {...props}/>
                </div>
                <br />
                <Genexer {...props} />
                <br />
                <div style={{display:'flex', justifyContent:'center'}}>
                        <SubmitButton {...props}/>
                </div>
                <br />
                <TextLog {...props} />
                <br />
                <div className='firstgroup'>
                        <TextBottom />
                        <LogoutButton />
                </div>
                <br /> <br /> <br /> <br /> <br /> <br />
            </div> /* rest of the flex */
        </div>
    );
};
